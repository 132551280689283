import { Component } from 'vue-property-decorator'
import { AUTH_LOGOUT_REQUEST, CommonBaseMixin } from 'booksprout-app'

@Component
export default class LogoutComponent extends CommonBaseMixin {
  public created () {
    this.$store.dispatch(AUTH_LOGOUT_REQUEST).then(() => {
      this.$router.push('login')
    })
  }
}
